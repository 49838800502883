import { useEffect, useRef, useState } from "react"
import {
  motion,
  useDragControls,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from "framer-motion"

export function ProgressBar({
  playing,
  done,
  reset,
  endReached = () => { },
  duration = 5,
}: Readonly<{
  playing: boolean
  done: boolean
  reset: boolean
  endReached: () => void
  duration: number
}>) {
  let [currentTime, setCurrentTime] = useState(0)
  let constraintsRef = useRef(null)
  let fullBarRef = useRef(null)
  let scrubberX = useMotionValue(0)
  let currentTimePrecise = useMotionValue(currentTime)
  let progressPrecise = useTransform(currentTimePrecise, (v) => {
    const val = (v / duration) * 100
    return clamp(val, 0, 100)
  })
  let progressPreciseWidth = useMotionTemplate`${progressPrecise}%`
  let dragControls = useDragControls()

  useInterval(
    () => {
      if (currentTime < duration) {
        setCurrentTime((t) => t + 1)
      }
      if (currentTime >= duration) {
        endReached()
        setCurrentTime(0)
      }
    },
    playing ? 1000 : null
  )

  useInterval(
    () => {
      if (currentTime < duration) {
        currentTimePrecise.set(currentTimePrecise.get() + 0.01)
        let newX = getXFromProgress({
          containerRef: fullBarRef,
          progress: currentTimePrecise.get() / duration,
        })
        scrubberX.set(newX)
      }
    },
    playing ? 10 : null
  )

  let widthStyle = done ? "100%" : "0%"
  if (!done && !reset) {
    widthStyle = (progressPreciseWidth as unknown) as string
  }
  return (
    <div className="relative w-full mt-[8px]">
      <div
        className="relative"
        onPointerDown={(event) => {
          let newProgress = getProgressFromX({
            containerRef: fullBarRef,
            x: event.clientX,
          })
          dragControls.start(event, { snapToCursor: true })
          setCurrentTime(Math.floor(newProgress * duration))
          currentTimePrecise.set(newProgress * duration)
        }}
      >
        <div
          ref={fullBarRef}
          className="w-full h-[3px] bg-[#fff] bg-opacity-40 rounded-full"
        ></div>

        <motion.div
          layout
          style={{ width: widthStyle }}
          className="absolute top-0"
        >
          <div className="absolute inset-0 h-[3px] bg-[#ffffff] bg-opacity-60 rounded-full"></div>
        </motion.div>
        <div
          className="absolute inset-0 -ml-[15px] -mr-[17px]"
          ref={constraintsRef}
        >
        </div>
      </div>
    </div>
  )
}

function getProgressFromX({ x, containerRef }: { x: number, containerRef: React.RefObject<any> }) {
  let bounds = containerRef.current.getBoundingClientRect()
  let progress = (x - bounds.x) / bounds.width

  return clamp(progress, 0, 1)
}

function getXFromProgress({ progress, containerRef }: { progress: number, containerRef: React.RefObject<any> }) {
  let bounds = containerRef.current.getBoundingClientRect()

  return progress * bounds.width
}

function clamp(number: number, min: number, max: number) {
  return Math.max(min, Math.min(number, max))
}

function useInterval(callback: unknown, delay: unknown) {
  const intervalRef = useRef<number | null>(null) // Update the type of intervalRef
  const savedCallback = useRef<() => void>(callback as () => void) // Add type annotation for savedCallback
  useEffect(() => {
    savedCallback.current = callback as () => void
  }, [callback])
  useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay)
      return () => {
        if (intervalRef.current !== null) {
          window.clearInterval(intervalRef.current)
        }
      }
    }
  }, [delay])
  return intervalRef
}
