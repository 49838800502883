import { createRef, useEffect, useState } from "react"
import "./styles.css"
import { LayoutGroup } from "framer-motion"
import { ProgressBar } from "./ProgressBar"
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import { useTimeout } from "usehooks-ts"
import { Drawer } from "vaul"
import clsx from "clsx"
import { useScreenshot } from "use-react-screenshot"

const studentDir = (id: number, storyIdx: number) => `/students/${id}/${storyIdx}.json`
const stories = [
  { duration: 1261 / 60 },
  { duration: 1689 / 60, sharesheet: { appear: 800 / 60, disappear: 1679 / 60, message: "I completed a lot of assignments!" } },
  { duration: 1519 / 60 },
  { duration: 1075 / 60, sharesheet: { appear: 796 / 60, disappear: 1054 / 60, message: "This is my learning style:" } },
  { duration: 2424 / 60, sharesheet: { appear: 2157 / 60, disappear: 2400 / 60, message: "One of my achievements this year" } },
  { duration: 1705 / 60 },
  { duration: 906 / 60 },
  { duration: 1152 / 60 },
  { duration: 2977 / 60 },
  { duration: 552 / 60 },
  { duration: 433 / 60, sharesheet: { appear: 156 / 60, message: "Check out my learning achievements" } },
]
const students = [
  { stories, firstName: "Alice", lastName: "Smith", },
  { stories, firstName: "Adam", lastName: "Smith", },
  { stories, firstName: "Connor", lastName: "Price", },
]

const randomIndex = Math.floor(Math.random() * students.length) % students.length;
const currentStudentIdx = parseInt(new URLSearchParams(window.location.search).get("student") ?? randomIndex.toString()) % students.length
const currentStudent = students[currentStudentIdx]
const proposedSlide = parseInt(new URLSearchParams(window.location.search).get("story") ?? "0") % currentStudent.stories.length

export default function App() {
  const [slideIndex, setSlideIndex] = useState(proposedSlide)
  const [playing, setPlaying] = useState(true)
  const [showShareSheet, setShowShareSheet] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        next()
      } else if (event.key === "ArrowLeft") {
        prev()
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [slideIndex])

  const timingToShowSharesheet = currentStudent.stories[slideIndex].sharesheet?.appear ?? 0
  useTimeout(() => {
    if (currentStudent.stories[slideIndex].sharesheet) {
      setShowShareSheet(true)
    }
  }, timingToShowSharesheet * 1000)

  const timingToHideSharesheet = currentStudent.stories[slideIndex].sharesheet?.disappear ?? 0
  useTimeout(() => {
    if (currentStudent.stories[slideIndex].sharesheet) {
      setShowShareSheet(false)
    }
  }, timingToHideSharesheet * 1000)

  const next = () => {
    if (slideIndex === currentStudent.stories.length - 1) {
      return
    }
    setSlideIndex((slideIndex + 1) % (currentStudent.stories.length))
    setPlaying(true)
  }

  const prev = () => {
    if (slideIndex === 0) {
      return
    }
    setSlideIndex(slideIndex - 1)
    setPlaying(true)
  }

  const [snap, setSnap] = useState<number | string | null>("148px")
  const playerRef = createRef<HTMLDivElement>()
  const [image, takeScreenshot] = useScreenshot()

  return (
    <div>
      <div className="slide"
        style={{
          gridTemplateRows: "1fr auto",
        }}>
        <div className="slide-items h-full w-full" ref={playerRef} >
          <Player
            autoplay
            src={studentDir(currentStudentIdx, slideIndex)}
            style={{ borderRadius: 16 }}
            className="h-full w-full"
            keepLastFrame

          >
            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
          </Player>
        </div>
        <nav
          className="slide-nav"
          style={{
            display: slideIndex === currentStudent.stories.length - 1 ? "none" : "grid",
            gridArea: "1 / 1",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "auto 1fr",
            zIndex: 1,
          }}
        >
          <div className="slide-thumbs">
            <LayoutGroup>
              {currentStudent.stories.map((_, index) => (
                <ProgressBar
                  key={index}
                  playing={playing && slideIndex === index}
                  done={slideIndex > index}
                  reset={slideIndex < index}
                  duration={currentStudent.stories[index].duration}
                  endReached={next}
                // endReached={() => { }}
                />
              ))}
            </LayoutGroup>
          </div>
          <div
            className="slide-prev"
            style={{
              backgroundColor: "blue",
            }}
            onPointerDown={() => setPlaying(false)}
            onPointerUp={prev}
          ></div>
          <div
            className="slide-next"
            style={{
              backgroundColor: "red",
            }}
            onPointerDown={() => setPlaying(false)}
            onPointerUp={next}
          ></div>
        </nav>
        {slideIndex === currentStudent.stories.length - 1 && (<iframe src="assets/song.mp3" allow="autoplay" style={{ display: 'none' }} id="iframeAudio" title="bgmusic">
        </iframe>)}
        <div style={{ display: showShareSheet ? "flex" : "none", justifyContent: "center" }}>

          <Drawer.Root
            snapPoints={["200", 1]}
            activeSnapPoint={snap}
            setActiveSnapPoint={setSnap}

          >
            <Drawer.Trigger asChild>
              <button
                className={`bg-blue-200 text-blue-900 m-3 hover:bg-blue-700 font-bold py-2 px-4 rounded-full ${slideIndex === currentStudent.stories.length - 1 ? 'w-2/5' : 'w-3/5'}`}
                onClick={() => takeScreenshot(playerRef.current)}>
                share
              </button>
            </Drawer.Trigger>
            <Drawer.Overlay className="fixed inset-0 bg-black/40" />
            <Drawer.Portal>
              <Drawer.Content className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[97%] mx-[-1px]">
                <div
                  className={clsx("flex flex-col max-w-md mx-auto w-full p-4 pt-5", {
                    "overflow-y-auto": snap === 1,
                    "overflow-hidden": snap !== 1,
                  })}
                >
                  <h1 className="text-2xl mt-2 mb-1 font-medium">Share on your socials</h1>
                  <p className="text-sm mt-3 text-gray-600 mb-3">
                    <div className="btn-o" data-scribe="component:button" style={{ width: 76, position: "relative", height: 28, boxSizing: "border-box", padding: "1px 12px 1px 12px", backgroundColor: "#000", color: "#fff", borderRadius: 9999, fontWeight: 500, cursor: "pointer", display: "inline-block", verticalAlign: "top", zoom: 1, }}>
                      <a href={`https://twitter.com/intent/tweet?text=Learning%20Wrapped%20%7C%20Hackathon%20project%20${currentStudent.stories[slideIndex].sharesheet?.message}&url=https%3A%2F%2Funwrapped.leonhuzen.nl%3Fstudent=${currentStudentIdx}%3Dstory=${slideIndex}`} className="btn" id="b">
                        <i style={{ top: 4, height: 18, width: 18, position: "relative", display: "inline-block", background: "transparent 0 0 no-repeat", backgroundImage: `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' fill='none'/%3E%3Cpath d='M17.9686 14.1623L26.7065 4H24.6358L17.0488 12.8238L10.9891 4H4L13.1634 17.3432L4 28H6.07069L14.0827 18.6817L20.4822 28H27.4714L17.9681 14.1623H17.9686ZM15.1326 17.4607L14.2041 16.132L6.81679 5.55961H9.99723L15.9589 14.0919L16.8873 15.4206L24.6368 26.5113H21.4564L15.1326 17.4612V17.4607Z' fill='white'/%3E%3C/svg%3E%0A")` }}></i>
                        <span className="label ml-1" id="l">Post</span>
                      </a>
                    </div>
                  </p>
                  <p className="text-gray-600">
                    This is a hackathon project, please don't share it on social media.
                  </p>

                  {image && <img src={image} alt={"ScreenShot"} />}
                </div>
              </Drawer.Content>
            </Drawer.Portal>
          </Drawer.Root >
          
          {slideIndex === currentStudent.stories.length - 1 &&
            <button
              className="bg-gray-200 text-black-900 m-3 w-2/5 hover:bg-gray-700 font-bold py-2 px-4 rounded-full"
              onClick={() => {
                setSlideIndex(0)
                setShowShareSheet(false)
              }}>
              restart
            </button>}      
        </div>
      </div>
    </div>
  )
}
